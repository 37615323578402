export const DownloadGuideData = [
  {
    id: 'melon.js',
    select: true,
    name: '멜론',
    icon: require('@/assets/images/musics/melon.png'),
    notionId: '392dd9cb042148f781a24f01266f45c1',
  },
  {
    id: 'genie',
    select: false,
    name: '지니',
    icon: require('@/assets/images/musics/genie.png'),
    notionId: 'cc08bf0ee87d453096c2ba2b8a8a7849',
  },
  // {
  //   id: 'flo',
  //   select: false,
  //   name: '플로',
  //   icon: require('@/assets/images/musics/flo.png'),
  //   notionId: '1336c96e377b4bd3aa6c043405388136',
  // },
  {
    id: 'vibe',
    select: false,
    name: '바이브',
    icon: require('@/assets/images/musics/vibe.png'),
    notionId: 'b594609e5fd140a7b0645a5ba4cd8ec9',
  },
  {
    id: 'bugs',
    select: false,
    name: '벅스',
    icon: require('@/assets/images/musics/bugs.png'),
    notionId: '7040065c6b0943a981613b32f6f09e46',
  },
  // {
  //   id: 'spotify',
  //   select: false,
  //   name: '스포티파이',
  //   icon: require('@/assets/images/musics/spotify.png'),
  //   notionId: '8b30a7aea8f7463080edae5256a8dd01',
  // },
];
