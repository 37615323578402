import { request } from '@/utils/networks';
import RequestError from '@/utils/networks/RequestError';
import axios from 'axios';

export const requestFetchNoticeList = async () => {
  try {
    const response = await axios.post('/api/notion/notice');
    // const response = await request<any>({
    //   method: 'post',
    //   url: '/v1/databases/719f773c6a1e4174b57b77d2bd37c292/query',
    //   // queryParams: params,
    // });
    return response.data;
  } catch (e) {
    throw new RequestError(e);
  }
};

export const requestFetchNoticeTitle = async () => {
  try {
    const response = await axios.post('/api/notion/notice/title');
    // const response = await request<any>({
    //   method: 'post',
    //   url: '/v1/databases/719f773c6a1e4174b57b77d2bd37c292/query',
    //   // queryParams: params,
    // });
    return response.data;
  } catch (e) {
    throw new RequestError(e);
  }
};
